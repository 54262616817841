import type { PaginatedReponse } from '@/components/composables/useDataTable/types'
import type { TenderDTO } from '@/services/tender/types'
import { CrossBorderCapacityDTO } from '@/services/crossBorderCapacity/types'
import { LotDTO, TenderOfferDTO } from '@/services/tender/types'
import { StatusResponse } from '@/services/types'

export enum ProcurementStatus {
  DRAFT = 'draft',
  APPROVED = 'approved',
  PUBLISHED = 'published',
  CLOSED = 'closed',
  EVALUATING_OFFERS = 'evaluatingOffers',
  EVALUATING_EXPIRED = 'evaluatingExpired',
  DECRYPTING_OFFERS = 'decryptingOffers',
}

export type MyTenderDTO = {
  id?: number
  name: string
  description?: string
  capacity: number
  timeRemaining?: number | null
  type: string
  isPublishedAt: string
  endsAt: string
  cbcCoverage: boolean
  crossBorderCapacityId: number
  direction?: string
  status: string
  offer: TenderOfferDTO
  crossBorderCapacity?: CrossBorderCapacityDTO
  lots: LotDTO[]
  termsAndConditions: string
  endTime: string
  endDate: string
  offerValidityHours?: number
  offerValidityMinutes?: number
}

export type GetMyTendersResponse = PaginatedReponse<TenderDTO>
export type FindMyTenderResponse = StatusResponse<{ procurement: MyTenderDTO }>
export type UpdateMyTenderResponse = FindMyTenderResponse
