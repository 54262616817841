import api from '@/plugins/api'
import type {
  CreateTenderResponse,
  DeleteTenderResponse,
  FindTenderResponse,
  GetAllTendersResponse,
  UpdateTenderResponse,
  GenerateContractResponse,
  GetTenderOfferResponse,
  GenerateInvoiceResponse,
  GetPublishedTenderOfferResponse,
} from './types'
import { StatusResponse } from '@/services/types'
import { LotBidsExcelResponse, LotBidsResponse, TermsAndConditionsResponse } from './types'

const TenderService = {
  all(query = ''): Promise<GetAllTendersResponse> {
    return api().get(`/procurements${query}`)
  },

  find(id: string | number): Promise<FindTenderResponse> {
    return api().get(`/procurements/${id}`)
  },

  publish(id: string | number): Promise<GetTenderOfferResponse> {
    return api().post(`/procurements/${id}/publish`)
  },

  offers(id: string | number): Promise<GetTenderOfferResponse> {
    return api().get(`/procurements/${id}/offers`)
  },

  winners(id: string | number, showWinners: boolean): Promise<GetTenderOfferResponse> {
    return api().get(`/procurements/${id}/lot-bids?winners=${showWinners}`)
  },

  published(id: string | number): Promise<GetPublishedTenderOfferResponse> {
    return api().get(`/procurements/${id}/ongoing`)
  },

  makeOffer(payload: Record<string, unknown>): Promise<FindTenderResponse> {
    return api().post(`/offers`, payload)
  },

  confirmBid(
    id: string | number,
    confirmedPower: number,
    powerExceededReason: string | null = null,
  ): Promise<FindTenderResponse> {
    return api().post(`/offers/lot-bids/${id}/confirm`, { confirmedPower, powerExceededReason })
  },

  revertBid(id: string | number): Promise<FindTenderResponse> {
    return api().post(`/offers/lot-bids/${id}/revert`)
  },

  complete(id: string | number, payload: Record<string, unknown>): Promise<FindTenderResponse> {
    return api().put(`/procurements/${id}/complete`, payload)
  },

  create(payload: Record<string, unknown>): Promise<CreateTenderResponse> {
    return api().post('/procurements', payload)
  },

  update(id: string | number, payload: Record<string, unknown>): Promise<UpdateTenderResponse> {
    return api().put(`/procurements/${id}`, payload)
  },

  delete(id: string | number): Promise<DeleteTenderResponse> {
    return api().delete(`/procurements/${id}`)
  },

  generateContract(id: string | number): Promise<GenerateContractResponse> {
    return api().post(`/contracts/${id}`)
  },

  sendContract(id: string | number): Promise<StatusResponse<undefined>> {
    return api().post(`/contracts/${id}/send`)
  },

  downloadContract(id: string | number): Promise<GenerateContractResponse> {
    return api().get(`/contracts/${id}/download`)
  },

  deleteContract(id: string | number): Promise<StatusResponse<undefined>> {
    return api().delete(`/contracts/${id}`)
  },

  generateInvoice(id: string | number): Promise<GenerateInvoiceResponse> {
    return api().post(`/contracts/invoices/${id}`)
  },

  sendInvoice(id: string | number): Promise<StatusResponse<undefined>> {
    return api().post(`/contracts/invoices/${id}/send`)
  },

  downloadInvoice(id: string | number): Promise<GenerateInvoiceResponse> {
    return api().get(`/contracts/invoices/${id}/download`)
  },

  downloadTermsAndConditions(id: string | number): Promise<TermsAndConditionsResponse> {
    return api().get(`/procurements/${id}/download-terms`)
  },

  downloadLotBids(id: string | number): Promise<StatusResponse<LotBidsResponse>> {
    return api().get(`/procurements/${id}/download-lotbids`)
  },

  downloadLotBidsExcel(id: string | number): Promise<StatusResponse<LotBidsExcelResponse>> {
    return api().get(`/procurements/${id}/excel-download-lotbids`)
  },

  deleteInvoice(id: string | number): Promise<StatusResponse<undefined>> {
    return api().delete(`/contracts/invoices/${id}`)
  },

  lot: (tenderId: string | number): { delete(lotId: string | number): Promise<StatusResponse<undefined>> } => ({
    delete(lotId: string | number) {
      return api().delete(`/procurements/${tenderId}/lots/${lotId}`)
    },
  }),

  fetchLimitedClients(id: string | number): Promise<StatusResponse<undefined>> {
    return api().get(`/procurements/${id}/limited-clients`)
  },

  assignLimitedClient(id: string | number, clientId: string | number): Promise<StatusResponse<undefined>> {
    return api().put(`/procurements/${id}/clients/${clientId}/limited-clients`)
  },

  deleteLimitedClient(id: string | number, clientId: string | number): Promise<StatusResponse<undefined>> {
    return api().delete(`/procurements/${id}/clients/${clientId}/limited-clients`)
  },
}

export default TenderService
