




import { defineComponent } from '@vue/composition-api'
import { ProcurementStatus } from '@/services/myTender/types'

export default defineComponent({
  name: 'TenderStatus',
  setup() {
    return { ProcurementStatus }
  },
  props: { status: { type: String, default: '' } },
  computed: {
    color(): { chip: string; text: string } {
      const status = this.status as ProcurementStatus
      const blueStatuses = [ProcurementStatus.PUBLISHED, ProcurementStatus.DECRYPTING_OFFERS]
      const orangeStatuses = [ProcurementStatus.CLOSED, ProcurementStatus.EVALUATING_EXPIRED]
      const greenStatuses = [ProcurementStatus.EVALUATING_OFFERS]

      if (greenStatuses.includes(status)) {
        return { chip: '#DFF4E0', text: 'green' }
      }

      if (blueStatuses.includes(status)) {
        return { chip: '#DEECEE', text: 'primary' }
      }

      if (orangeStatuses.includes(status)) {
        return { chip: '#FFE5E7', text: 'red' }
      }

      return { chip: '#F1E1D1', text: 'orange' }
    },
  },
})
